/* ==================
	8.23 Work Box
=================== */
.work-area{
	.pt-img1{
		bottom: 15%;
		left: 5%;
	}
	.pt-img2{
		top: 50%;
		right: 5%;
		@include respond('tab-port'){
			top: 250px;
			width: 100px;
			height: 100px;
			right: 10px;
		}
	}
	.pt-img3{
		bottom: 10%;
		right: 20%;
		@include respond('tab-port'){
			bottom: 120px;
			right: 430px;
		}
	}
	.work-bx{
		margin-top: 30px;
		@include respond('tab-port'){
			margin-top: 0;			
		}
	}
	.work-bx.active{
		margin-top: 0;
	}
}
.work-bx{
	position:relative;
	// box-shadow: 0px 10px 60px 0px rgba(31, 34, 120, 0.1);
	padding:20px 30px;
	border-radius:20px;
	background-color:$light;
	@include transitionMedium;
	overflow: hidden;
	z-index: 1;

	// &:after{
	// 	content: "";
	// 	height: 0;
	// 	width: 100%;
	// 	background-color: $secondary;
	// 	position: absolute;
	// 	top: 0;
	// 	right: 0;
	// 	z-index: -1;
	// 	@include transitionMedium;
	// 	transform-origin: top;
	// 	-moz-transform-origin: top;
	// 	-ms-transform-origin: top;
	// 	-webkit-transform-origin: top;
	// 	-o-transform-origin: top;
	// }
	
	.work-num-bx {
		@include transitionMedium;
		z-index:9;
		font-size: 110px;
		font-weight: 600;
		margin-bottom: 30px;
		line-height: 24px;
		// -webkit-text-stroke: 2px #D89D29;
		text-shadow: 0 10px 0 rgba(216, 157, 41, 0.2)		;
		color: transparent;
	}
	.work-content{
		transition: all 0.5s;
		@include transitionMedium;
		p{
			font-size:14px;
			line-height:24px;
			font-weight:500;
			margin-bottom:30px;
		}
	}
	.btn{
		font-weight: 600;
	}
	
	
}